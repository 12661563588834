import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from '../../styles/theme';

const fade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Button = styled.button`
  box-sizing: content-box;
  border: none;
  outline: inherit;
  background-color: transparent;
  cursor: pointer;
  animation: ${fade} 0.25s ease-in-out 0s forwards;
  padding: 10px;
  margin: -6px -10px -10px -10px;
  width: 6vw;
  max-width: 32px;
`;

type MenuButtonProps = { onClick: () => void };

const MenuButton = ({ onClick }: MenuButtonProps): JSX.Element => (
  <Button type="button" onClick={onClick} aria-label="Open menu">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 13" width="100%">
      <g
        fill="none"
        fillRule="evenodd"
        stroke={theme.palette.secondary}
        strokeWidth="3"
      >
        <path d="M0 1.5h23M0 11.5h23" />
      </g>
    </svg>
  </Button>
);

MenuButton.propTypes = { onClick: PropTypes.func.isRequired };

export default MenuButton;
