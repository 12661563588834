import idle0 from './bodymovin/idle_00.json';
import idle1 from './bodymovin/idle_01.json';
import idle2 from './bodymovin/idle_02.json';
import idle3 from './bodymovin/idle_03.json';
import idle4 from './bodymovin/idle_04.json';
import idle5 from './bodymovin/idle_05.json';
import smile0 from './bodymovin/smile_00.json';
import surprised0 from './bodymovin/suprised_00.json';
import sad0 from './bodymovin/sad_00.json';
import teardrop0 from './bodymovin/teardrop_00.json';
import singing0 from './bodymovin/singing_00.json';
import annoyed0 from './bodymovin/annoyed_00.json';
import flirting0 from './bodymovin/flirting_00.json';
import nervous0 from './bodymovin/nervous_00.json';
import waving0 from './bodymovin/waving_00.json';
import wavingMitten0 from './bodymovin/waving_mitten_00.json';
import wavingMitten1 from './bodymovin/waving_mitten_01.json';
import proStill from './bodymovin/pro_still.json';
import buddle from './bodymovin/buddle_still.json';

export const Animations = {
  idle0,
  idle1,
  idle2,
  idle3,
  idle4,
  idle5,
  smile0,
  surprised0,
  sad0,
  teardrop0,
  singing0,
  annoyed0,
  flirting0,
  nervous0,
  waving0,
  wavingMitten0,
  wavingMitten1,
  buddle,
};

export const AnimationsAdult = {
  idle0,
  idle1,
  idle2,
  idle3,
  idle4,
  idle5,
  sad0,
  nervous0,
  still: proStill,
};

export const DefaultAnimations = [
  Animations.idle0,
  Animations.idle1,
  Animations.idle2,
  Animations.idle3,
  Animations.idle4,
  Animations.idle5,
];
