import Auth0 from 'auth0-js';
import type { WebAuth } from 'auth0-js';
// import { nanoid } from 'nanoid';
import env from '../utils/env';

import { setToken } from '../utils/cookie';
import { createUrl } from '../utils/url';

const { AUTH0_CLIENT_ID, AUTH0_DOMAIN } = env;

const determineLoginRedirectUrl = (host) => createUrl(host, '/login');

class AuthApi {
  webAuth: WebAuth;

  constructor() {
    this.webAuth = null;
  }

  getWebAuth(host): Auth0.WebAuth {
    if (!this.webAuth) {
      // const state = window.localStorage.getItem('state');
      this.webAuth = new Auth0.WebAuth({
        clientID: AUTH0_CLIENT_ID,
        domain: AUTH0_DOMAIN,
        redirectUri: determineLoginRedirectUrl(host),
        responseType: 'token id_token',
        scope: 'openid profile',
        // state,
      });
    }
    return this.webAuth;
  }

  // eslint-disable-next-line class-methods-use-this
  async startPasswordless(phoneNumber, locale, callback) {
    // NOTE: We are using fetch here because the Auth0 package is missing a feature.
    // See: https://github.com/auth0/auth0.js/issues/1051
    // TODO: Now language can be set with https://github.com/auth0/auth0.js/pull/1210 in v9.17.0
    // types is not there yet
    // const response = this.getWebAuth(host).passwordlessStart(
    //   {
    //     connection: 'sms',
    //     send: 'code',
    //     phoneNumber,
    //     xRequestLanguage: locale,
    //   },
    //   callback,
    // );
    const response = await fetch(`https://${AUTH0_DOMAIN}/passwordless/start`, {
      method: 'POST',
      headers: {
        'x-request-language': locale,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        client_id: AUTH0_CLIENT_ID,
        connection: 'sms',
        send: 'code',
        phone_number: phoneNumber,
      }),
    });
    return callback(
      response.ok ? null : response.statusText || response.status !== 200,
    );
  }

  verifyCode(host, phoneNumber, verificationCode, callback) {
    return this.getWebAuth(host).passwordlessLogin(
      {
        connection: 'sms',
        phoneNumber,
        verificationCode,
      },
      callback,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  async startEmailPasswordless(email: string, locale: string, callback) {
    // nonce is used by the AuthServer to prevent replay attacks, it tracks nonces and will reject a request that tries to reuse a nonce.
    // see https://auth0.com/docs/api-auth/tutorials/nonce
    // const nonce = nanoid();
    // state is used by the client to prevent CSRF of a callback by validating the state.
    // The AS passes the state parameter through as received. The client keeps track of state to be able to validate it when it receives the callback.
    // see https://auth0.com/docs/protocols/state-parameters
    // const state = nanoid();
    // window.localStorage.setItem('state', state);
    // NOTE: We are using fetch here because the Auth0 package is missing a feature.
    // See: https://github.com/auth0/auth0.js/issues/1051 and https://auth0.com/docs/connections/passwordless/guides/sms-otp#multi-language-support

    const response = await fetch(`https://${AUTH0_DOMAIN}/passwordless/start`, {
      method: 'POST',
      headers: {
        'x-request-language': locale,
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        client_id: AUTH0_CLIENT_ID,
        connection: 'email',
        send: 'code',
        email,
      }),
    });
    if (response.ok) {
      return callback(null);
    }
    return response.text().then((text) => {
      try {
        const description = JSON.parse(text).error_description;
        callback(description);
      } catch (err) {
        callback(text);
      }
    });
  }

  verifyEmailCode(host, email, verificationCode, callback) {
    return this.getWebAuth(host).passwordlessLogin(
      {
        connection: 'email',
        email,
        verificationCode,
      },
      callback,
    );
  }

  parseToken(hash, host) {
    return new Promise((resolve, reject) => {
      // const state = window.localStorage.getItem('state');
      this.getWebAuth(host).parseHash({ hash }, (err, authResult) => {
        if (err) {
          console.log({ err });
          reject(err);
        } else {
          const token = authResult.idToken;
          setToken(token);
          resolve(token);
        }
      });
    });
  }

  async renewToken(host) {
    return new Promise((resolve, reject) => {
      this.getWebAuth(host).checkSession({}, async (err, authResult) => {
        if (err) {
          console.log({ err });
          reject(err);
        } else {
          const token = authResult.idToken;
          setToken(token);
          resolve(token);
        }
      });
    });
  }

  async logout(host, options) {
    setToken('', 0);
    return this.getWebAuth(host).logout(options);
  }
}

export default new AuthApi();
