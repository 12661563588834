import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import Lottie from 'lottie-react-web';
import { AvatarContext } from '../../contexts/AvatarContext';

type AvatarContaierProps = {
  width: number;
  height: number;
};

const AvatarContainer = styled.div<AvatarContaierProps>`
  && {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: relative;
  }
  && svg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const getNextAnimation = (queue, animation) => {
  const withoutCurrentAnimation = queue.filter((q) => q !== animation);
  const { length } = withoutCurrentAnimation;
  return length
    ? withoutCurrentAnimation[getRandomInt(0, length - 1)]
    : queue[0];
};

const useAnimationQueue = (nextAnimationQueue, singleAnimation) => {
  const [currentQueue, setCurrentQueue] = useState(nextAnimationQueue);
  const [currentAnimation, setAnimation] = useState(nextAnimationQueue[0]);
  // This is needed to play same single animation twice in a row
  const [isStopped, setIsStopped] = useState(false);

  // This is needed to enable clearTimeout on unmount
  const timeout = useRef(null);

  const playAnimation = (animation) => {
    setIsStopped(true);
    setAnimation(animation);
  };

  useEffect(() => {
    setCurrentQueue(nextAnimationQueue);
    playAnimation(nextAnimationQueue[0]);
  }, [nextAnimationQueue]);

  useEffect(() => {
    if (isStopped) {
      clearTimeout(timeout.current);
      const nextAnimation = getNextAnimation(currentQueue, currentAnimation);
      timeout.current = setTimeout(() => playAnimation(nextAnimation), 9000);
    }
    setIsStopped(false);
  }, [isStopped]);

  useEffect(() => () => clearTimeout(timeout.current), []);

  useEffect(() => {
    if (singleAnimation.animation) {
      playAnimation(singleAnimation.animation);
    }
  }, [singleAnimation]);

  return { currentAnimation, isStopped };
};

type AvatarResizerProps = {
  $dimension: number;
};

const multiplier = 2.5;
const AvatarResizer = styled.div.attrs<{ $dimension?: number }>((props) => ({
  $dimension: props.$dimension,
}))<AvatarResizerProps>`
  position: absolute;
  width: ${({ $dimension }) => `${$dimension * multiplier}px`};
  height: ${({ $dimension }) => `${$dimension * multiplier}px`};
  top: ${({ $dimension }) => `-${($dimension * multiplier) / 3.3}px`};
  left: ${({ $dimension }) => `-${($dimension * multiplier) / 3.3}px`};
  div {
    position: relative;
  }
`;

type AvatarProps = {
  playLoop?: any;
  width?: number;
  height?: number;
  className?: string;
};

const Avatar = ({ width, height, className, playLoop }: AvatarProps) => {
  const { animationQueue, singleAnimation } = useContext(AvatarContext);

  const { currentAnimation, isStopped } = useAnimationQueue(
    animationQueue,
    singleAnimation,
  );
  const animationData = playLoop || currentAnimation;

  return (
    <AvatarContainer width={width} height={height} className={className}>
      <AvatarResizer $dimension={width}>
        <Lottie
          isStopped={isStopped}
          options={{ animationData, loop: playLoop !== undefined }}
        />
      </AvatarResizer>
    </AvatarContainer>
  );
};

Avatar.defaultProps = {
  playLoop: undefined,
  width: 50,
  height: 50,
};

export default Avatar;
