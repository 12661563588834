import styled from 'styled-components';
import { theme, width } from '../styles/theme';

const Button = styled.button`
  font-family: 'Gramatika', sans-serif;
  height: 60px;
  padding-left: 3.2em;
  padding-right: 3.2em;
  border-radius: 2em;
  font-size: 14pt;
  font-weight: 200;
  transition: opacity 0.1s linear;
  margin: 0 auto;
  z-index: 9;
  cursor: pointer;
  background-color: ${theme.palette.secondary};
  border-color: ${theme.palette.primary};
  border-width: 0;
  color: ${theme.palette.white};
  &:disabled {
    background-color: ${theme.palette.disabled};
    color: ${theme.palette.white};
  }
  width: 90%;
  max-width: 320px;

  @media (min-width: ${width.tablet}px) {
    width: auto;
  }
`;

export default Button;
