import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import NavMenu from '../NavMenu/NavMenu';
import MenuButton from './MenuButton';
import Avatar from '../Avatar/Avatar';
import { PeikkoContext } from '../../contexts/PeikkoContext';

const HeaderContainer = styled.div`
  position: sticky;
  margin: 0 auto;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 26px 0;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 25px;
`;

interface HeaderProps {
  user: User;
}

const Header = ({ user }: HeaderProps): React.ReactElement => {
  const [showNavMenu, setShowNavMenu] = useState(false);
  const { showHeaderLogo } = useContext(PeikkoContext);
  return (
    <HeaderContainer role="navigation">
      <ActionContainer>
        {showHeaderLogo ? <Avatar width={70} height={70} /> : <div />}
        <MenuButton onClick={() => setShowNavMenu(!showNavMenu)} />
        <NavMenu
          isOpen={showNavMenu}
          closeMenu={() => setShowNavMenu(false)}
          user={user}
        />
      </ActionContainer>
    </HeaderContainer>
  );
};

export default Header;
