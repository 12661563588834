import flatMap from 'lodash/flatMap';
import env from './env';
import { countries } from '../components/i18n';

const { TOOSA_URL, TOOSA_URL_SE } = env;

const fiMapping = {
  URL: TOOSA_URL,
  TITLE: 'Someturva',
  COUNTRY_PHONE_CODE: '+358',
};

const getLocaleMapping = (locale = 'fi-FI') => {
  const mapping = {
    'fi-FI': fiMapping,
    'sv-FI': fiMapping,
    'sv-SE': {
      URL: TOOSA_URL_SE,
      TITLE: 'SomeBuddy',
      COUNTRY_PHONE_CODE: '+46',
    },
    'en-FI': fiMapping,
  };
  return mapping[locale] || fiMapping;
};

export const allowedLocales: string[] = [
  ...flatMap(countries, (country) => country.languages.map((_) => _.locale)),
];
export const allowedCountries: string[] = [
  'GLOBAL',
  ...flatMap(countries, (country) => country.shortname),
];

export const getToosaUrlByLocale = (locale): string =>
  getLocaleMapping(locale).URL;

export const getTitleByLocale = (locale): string =>
  getLocaleMapping(locale).TITLE;

export const getCountryPhoneCodeByLocale = (locale) =>
  getLocaleMapping(locale).COUNTRY_PHONE_CODE;

export const getLocaleForHTML = (user): string =>
  user ? JSON.parse(user).locale : null;
