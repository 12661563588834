import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const StyledNavMenuButton = styled.button`
  color: ${theme.palette.background};
  background-color: inherit;
  outline: inherit;
  font-size: 20px;
  font-weight: 500;
  width: 100%;
  padding: 15px 20px;
  text-align: left;
  cursor: pointer;
  border: 0;
`;

export const StyledSmallNavMenuButton = styled(StyledNavMenuButton)`
  font-size: 14px;
  padding: 10px 20px;
  color: rgba(255, 255, 255, 0.8);
`;

export const StyledSmallestNavMenuButton = styled(StyledNavMenuButton)`
  font-size: 12px;
  padding: 5px 0;
  color: rgba(255, 255, 255, 0.6);
`;

export const SmallNavMenuButton = ({ children, ...rest }) => (
  <StyledSmallNavMenuButton type="button" {...rest}>
    {children}
  </StyledSmallNavMenuButton>
);

export const SmallestNavMenuButton = ({ children, ...rest }) => (
  <StyledSmallestNavMenuButton as="div" {...rest}>
    {children}
  </StyledSmallestNavMenuButton>
);

SmallNavMenuButton.propTypes = { children: PropTypes.node.isRequired };

const NavMenuButton = ({ children, ...rest }) => (
  <StyledNavMenuButton type="button" {...rest}>
    {children}
  </StyledNavMenuButton>
);

NavMenuButton.propTypes = { children: PropTypes.node.isRequired };

export default NavMenuButton;
