import fetch from 'isomorphic-unfetch';
import env from '../utils/env';
import AuthApi from '../apis/AuthApi';
import { getToken } from '../utils/cookie';
class CustomError extends Error {
  response: Response;
  status: number;

  constructor(response = null, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }

    this.name = 'CustomError';
    this.status = response.status;
    this.response = response;
  }
}

// NB. exported only for testing
export const wrapReq = async (request) => {
  const response = await request;
  if (response.ok) {
    return response.json();
  }
  throw new CustomError(response);
};

export const updateUser = async (token, locale) =>
  await wrapReq(
    fetch(`${env.URL_API_ROOT}/user`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        cookie: `token=${token}`,
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ locale }),
    }),
  );

export const verifyUser = async (token = false) =>
  await wrapReq(
    fetch(`${env.URL_API_ROOT}/user`, {
      credentials: 'include',
      headers: {
        cookie: `token=${token || getToken()}`,
        authorization: `Bearer ${token || getToken()}`,
      },
    }),
  );
interface ActivateUser {
  (
    token: string,
    code: string,
    locale: string,
    host: string,
    n?: number,
  ): Promise<object>;
}

export const activateUser: ActivateUser = async (
  token,
  code,
  locale,
  host,
  n = 2,
) => {
  const response = await fetch(`${env.URL_API_ROOT}/user/activate`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      cookie: `token=${token}`,
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ code, locale }),
  });

  if (response.status === 401 && n > 1) {
    const newToken = await AuthApi.renewToken(host);
    return activateUser(newToken as string, code, locale, host, n - 1);
  }
  if (response.ok) {
    return response.json();
  }
  throw new CustomError(response);
};

interface GetVoucherInfo {
  (
    token: string,
    code: string,
    locale: string,
    host: string,
    n?: number,
  ): Promise<{ product: string }>;
}

export const getVoucherInfo: GetVoucherInfo = async (
  token,
  code,
  locale,
  host,
  n = 2,
) => {
  const response = await fetch(`${env.URL_API_ROOT}/voucher/${code}`, {
    credentials: 'include',
    headers: {
      cookie: `token=${token || getToken()}`,
      authorization: `Bearer ${token || getToken()}`,
    },
  });

  if (response.status === 401 && n > 1) {
    const newToken = await AuthApi.renewToken(host);
    return getVoucherInfo(newToken as string, code, locale, host, n - 1);
  }
  if (response.ok) {
    return response.json();
  }
  throw new CustomError(response);
};
