/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { theme } from '../../styles/theme';

const SomisLogo = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 67"
    width="100%"
    height="100%"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M38.887 53.537H33.55c-3.76 0-7.13 2.793-7.405 6.566a7.031 7.031 0 001.663 5.102c.665.782-.058 1.98-1.057 1.77C10.874 63.654-.903 49.092.054 31.918 1.057 13.838 16.57 0 34.564 0h12.84c14.857 0 26.867 12.261 26.591 27.269-.263 14.46-13.1 26.268-27.473 26.268h-7.635z"
      />
      <g fill={theme.palette.secondary}>
        <path d="M38.373 19.014c0-1.665-1.342-3.019-2.993-3.019-1.651 0-2.994 1.354-2.994 3.019 0 1.664 1.343 3.018 2.994 3.018 1.65 0 2.993-1.354 2.993-3.018zM54.84 22.032c1.65 0 2.993-1.354 2.993-3.018 0-1.665-1.343-3.019-2.994-3.019-1.65 0-2.993 1.354-2.993 3.019 0 1.664 1.342 3.018 2.993 3.018" />
      </g>
    </g>
  </svg>
);

SomisLogo.propTypes = { fill: PropTypes.string };

SomisLogo.defaultProps = { fill: theme.palette.primary };

export default SomisLogo;
