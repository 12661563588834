import React from 'react';
import App from 'next/app';
import get from 'lodash/get';
import Head from 'next/head';
import styled from 'styled-components';
import * as Sentry from '@sentry/nextjs';
import { PageTransition } from 'next-page-transitions';
import nookies from 'nookies';
import Router from 'next/router';
import { theme, width } from '../styles/theme';
import { PeikkoProvider } from '../contexts/PeikkoContext';
import { AvatarProvider } from '../contexts/AvatarContext';
import Splash from '../components/Splash/Splash';
import Header from '../components/Header/Header';
import { verifyUser } from '../apis/IdentifyApi';
import {
  setUser,
  setLocale,
  setCountry,
  isCookiesConsentGiven,
  checkTermsAccepted,
} from '../utils/cookie';
import env from '../utils/env';
import {
  GDPR_FUNCTIONAL_ENABLED,
  GDPR_STATISTICS_ENABLED,
} from '../utils/constants';
import Analytics from '../utils/analytics';
import Error from './_error';
import { getHost, isServerRender } from '../utils/url';
import {
  getToosaUrlByLocale,
  allowedLocales,
  getTitleByLocale,
} from '../utils/locale';
import { countries } from '../components/i18n';
import { getCountryFromLocale } from '../utils/helpers';

import '../styles/globals.css';

const { MAINTENANCE_MODE } = env;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

const AppContainer = styled.div`
  width: ${width.container}px;
  margin: 0 auto;
  background: ${theme.palette.white};
  height: 100%;
  overflow-y: auto;

  @media (max-width: ${width.container - 1}px) {
    width: 100%;
  }
`;

function redirect(url, ctx?) {
  if (isServerRender(ctx)) {
    const { locale } = ctx.query;
    const protocol = ctx.req.connection.encrypted ? 'https' : 'http';
    const { host } = ctx.req.headers;
    const parsedUrl = new URL(`${protocol}://${host}${url}`);
    const queryParams = new URLSearchParams(parsedUrl.search);
    const country = queryParams.get('country');
    // passing locale from toosa, but only if it's a normal locale, not a country
    if (locale && locale !== country) {
      ctx.res.writeHead(302, {
        Location: `${parsedUrl.href}?locale=${locale}`,
      });
    } else {
      ctx.res.writeHead(302, { Location: url });
    }
    ctx.res.end();
  } else if (url.startsWith('/')) {
    Router.push(url);
  } else {
    window.location = url;
  }
  return {};
}

export const getToosaRedirectUrl = ({
  token,
  user,
  ctx,
  locale,
  cookieFunctional,
  cookieStatistics,
}: any): string => {
  const userAsString = typeof user === 'string' ? user : JSON.stringify(user);
  const base64User = isServerRender(ctx)
    ? Buffer.from(userAsString).toString('base64')
    : window.btoa(userAsString);
  return `${getToosaUrlByLocale(
    locale,
  )}/login?token=${token}&user=${base64User}&cookieFunctional=${cookieFunctional}&cookieStatistics=${cookieStatistics}`;
};

const getRedirect = async (
  ctx,
  locale,
  gdprVersion,
  token,
  user,
  onboarding,
  preferLoginVariant = 'phone',
  gdprConsentGiven,
  cookieFunctional,
  cookieStatistics,
): Promise<any> => {
  const unauthorizedPath = '/unauthorized';

  // whether user can directly open particular pages
  const noRedirectWithoutTokenPaths = [
    '/',
    '/email',
    unauthorizedPath,
    '/login',
    '/logout',
    '/cross-origin-verification',
    '/locale',
    '/country',
    '/maintenance',
  ];
  const noRedirectWithTokenPaths = [
    unauthorizedPath,
    '/logout',
    '/maintenance',
  ];

  const defaultOutput = { pageProps: {} };

  if (MAINTENANCE_MODE === true && ctx.pathname !== '/maintenance') {
    return { ...defaultOutput, redirect: `/maintenance` };
  }

  if (ctx.pathname === '/maintenance') {
    return defaultOutput;
  }

  const countryLocales: string[] = countries.map((_) => _.shortname);

  if (noRedirectWithTokenPaths.includes(ctx.pathname)) {
    return defaultOutput;
  }

  // if country set as locale (like 'FI') then we can skip /country
  if (ctx.pathname !== '/locale' && locale && countryLocales.includes(locale)) {
    return { ...defaultOutput, redirect: `/locale?country=${locale}` };
  }

  // if no valid locale is found, then go to country and locale selection
  if (!locale || !allowedLocales.includes(locale)) {
    if (ctx.pathname === '/country' || ctx.pathname === '/locale') {
      return defaultOutput;
    }
    return { ...defaultOutput, redirect: '/country' };
  }

  // if user wants to change a language from the menu
  if (ctx.pathname === '/country' || ctx.pathname === '/locale') {
    return defaultOutput;
  }

  const checkOnboardingCompleted = (onboardingCookieValue: string): boolean =>
    onboardingCookieValue === 'v1';

  // if onboarding is not completed yet
  if (!checkOnboardingCompleted(onboarding)) {
    if (ctx.pathname === '/onboarding/1' || ctx.pathname === '/onboarding/2') {
      return defaultOutput;
    }
    return {
      ...defaultOutput,
      redirect: `/onboarding/1`,
    };
  }

  // if terms is not accepted
  if (!checkTermsAccepted(gdprVersion)) {
    if (ctx.pathname === '/terms' || ctx.pathname === '/privacy') {
      return defaultOutput;
    }
    return { ...defaultOutput, redirect: '/terms' };
  }

  // if cookie consent is not given
  if (!isCookiesConsentGiven(gdprConsentGiven)) {
    if (
      ctx.pathname === '/cookies/consent' ||
      ctx.pathname === '/cookies/settings'
    ) {
      return defaultOutput;
    }
    return {
      ...defaultOutput,
      redirect: '/cookies/consent',
    };
  }

  if (
    !token &&
    !noRedirectWithoutTokenPaths.includes(ctx.pathname) &&
    isServerRender(ctx)
  ) {
    return { ...defaultOutput, redirect: '/' };
  } else if (token && !noRedirectWithTokenPaths.includes(ctx.pathname)) {
    try {
      const verifiedUser = await verifyUser(token);
      const redirectPath =
        verifiedUser.status === 'inactive'
          ? '/voucher'
          : getToosaRedirectUrl({
              token,
              user,
              ctx,
              locale,
              cookieFunctional,
              cookieStatistics,
            });
      return {
        ...defaultOutput,
        pageProps: { user: verifiedUser, redirectPath },
      };
    } catch (err) {
      if (err.status === 401 && ctx.pathname === '/') {
        return { ...defaultOutput, redirect: '/expired-token' };
      } else if (err.status === 400 && ctx.pathname === '/') {
        return { ...defaultOutput, redirect: '/voucher' };
      }
    }
  }

  // if user logged in with an email previously
  if (preferLoginVariant === 'email' && ctx.pathname === '/') {
    return { ...defaultOutput, redirect: '/email' };
  }

  return defaultOutput;
};

const pagesWithNoHeader = ['/maintenance'];

const getInitialProps = async (Component, ctx) => {
  const defaultOutput = { initialProps: {}, hasError: false };
  if (pagesWithNoHeader.includes(ctx.pathname)) {
    return { ...defaultOutput, initialProps: { disableHeader: true } };
  }

  if (Component.getInitialProps) {
    try {
      return {
        ...defaultOutput,
        initialProps: await Component.getInitialProps(ctx),
      };
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(e);
      }
      Sentry.captureException(e);
      return { ...defaultOutput, hasError: true };
    }
  }
  return defaultOutput;
};

type InitialLocaleUser = {
  locale?: string;
};

type InitialLocaleQuery = {
  locale?: string;
  country?: string;
};

const getInitialLocale = (
  user: InitialLocaleUser = {},
  query: InitialLocaleQuery = {},
): string => {
  if (user.locale) return user.locale;
  if (query.locale) return query.locale;
  return undefined;
};

type PeikkoAppProps = {
  pageProps: any;
};

class PeikkoApp extends App<PeikkoAppProps> {
  static async getInitialProps({ Component, ctx }): Promise<any> {
    const {
      user,
      token,
      gdprVersion,
      locale: cookieLocale,
      onboarding = '',
      preferLoginVariant,
      gdprConsentGiven,
      [GDPR_FUNCTIONAL_ENABLED]: cookieFunctional = 'false',
      [GDPR_STATISTICS_ENABLED]: cookieStatistics = 'false',
    }: any = nookies.get(ctx);
    const host = getHost(ctx);
    const localeFromQueryParams = getInitialLocale(user, ctx.query); // locale from toosa

    const locale = localeFromQueryParams || cookieLocale;

    const defaultPageProps = {
      token,
      pathname: ctx.pathname,
      host,
      locale,
      cookieFunctional: cookieFunctional === 'true',
    };

    const { initialProps, hasError } = await getInitialProps(Component, ctx);
    const props = {
      pageProps: { ...defaultPageProps, ...initialProps },
      hasError,
    };

    const { pageProps: pagePropsUpdates, redirect: redirectPath } =
      await getRedirect(
        ctx,
        locale,
        gdprVersion,
        token,
        user,
        onboarding,
        preferLoginVariant,
        gdprConsentGiven,
        cookieFunctional,
        cookieStatistics,
      );

    return redirectPath
      ? redirect(redirectPath, ctx)
      : { ...props, pageProps: { ...props.pageProps, ...pagePropsUpdates } };
  }

  componentDidMount() {
    const pageProps = get(this.props, 'pageProps', {});
    const { user, redirectPath, pathname, locale } = pageProps;
    Analytics.setTrackingOptions(user);
    if (locale && allowedLocales.includes(locale)) {
      setLocale(locale);
      setCountry(getCountryFromLocale(locale));
    }
    if (user) {
      setUser(user);
    }
    if (redirectPath && redirectPath !== pathname) {
      redirect(redirectPath);
    }
  }

  render() {
    const { Component, pageProps, router, hasError }: any = this.props;
    const { locale, disableHeader, user } = pageProps;
    return (
      <>
        <Splash locale={locale} title={getTitleByLocale(locale)} />
        <AppContainer>
          <Head>
            <title>{getTitleByLocale(locale)}</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <link rel="shortcut icon" href="/favicon-32.png" />
            <link
              rel="preload"
              href="/fonts/Gramatika-Bold.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
          </Head>
          <AvatarProvider>
            <PeikkoProvider locale={pageProps.locale}>
              {disableHeader ? null : <Header user={user} />}
              <ComponentContainer role="main">
                <PageTransition timeout={50} classNames="page-transition">
                  {hasError ? (
                    <Error />
                  ) : (
                    <Component {...pageProps} key={router.route} />
                  )}
                </PageTransition>
              </ComponentContainer>
            </PeikkoProvider>
          </AvatarProvider>
        </AppContainer>
      </>
    );
  }
}

export default PeikkoApp;
