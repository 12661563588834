/* eslint-disable max-len */
import frenchkiss from 'frenchkiss/dist/umd/frenchkiss';
import Link from 'next/link';
import React from 'react';
import PropTypes from 'prop-types';

const PRIVACY_POLICY_LINK_FI =
  'https://someturva.fi/legal/privacy-policy-service';
const PRIVACY_POLICY_LINK_EN_FI =
  'https://www.someturva.fi/en-fi/legal/privacy-policy-service';
const PRIVACY_POLICY_LINK_SV_FI =
  'https://someturva.fi/sv-fi/legal/privacy-policy-service';
const PRIVACY_POLICY_LINK_SV = 'https://somebuddy.se/legal/privacy-policy';

const SERVICE_AGREEMENT_LINK_FI = 'https://someturva.fi/legal/terms-of-service';
const SERVICE_AGREEMENT_LINK_EN_FI =
  'https://www.someturva.fi/en-fi/legal/terms-of-service';
const SERVICE_AGREEMENT_LINK_SV_FI =
  'https://someturva.fi/sv-fi/legal/terms-of-service';
const SERVICE_AGREEMENT_LINK_SV = 'https://somebuddy.se/legal/terms-of-service';

const COOKIE_POLICY_LINK_FI = 'https://someturva.fi/legal/cookie-policy';
const COOKIE_POLICY_LINK_EN_FI =
  'https://www.someturva.fi/en-fi/legal/cookie-policy';
const COOKIE_POLICY_LINK_SV_FI =
  'https://someturva.fi/sv-fi/legal/cookie-policy';
const COOKIE_POLICY_LINK_SV = 'https://somebuddy.se/legal/cookie-policy';

const LEGAL_PAGE_LINK_FI_FI = 'https://someturva.fi/legal';
const LEGAL_PAGE_LINK_EN_FI = 'https://www.someturva.fi/en-fi/legal';
const LEGAL_PAGE_LINK_SV_FI = 'https://someturva.fi/sv-fi/legal';
const LEGAL_PAGE_LINK_SV_SE = 'https://somebuddy.se/legal';

const privacyPolicyLinks = {
  'fi-FI': PRIVACY_POLICY_LINK_FI,
  'sv-SE': PRIVACY_POLICY_LINK_SV,
  'sv-FI': PRIVACY_POLICY_LINK_SV_FI,
  'en-FI': PRIVACY_POLICY_LINK_EN_FI,
};

const serviceAgreementLinks = {
  'fi-FI': SERVICE_AGREEMENT_LINK_FI,
  'sv-SE': SERVICE_AGREEMENT_LINK_SV,
  'sv-FI': SERVICE_AGREEMENT_LINK_SV_FI,
  'en-FI': SERVICE_AGREEMENT_LINK_EN_FI,
};

const cookiePolicyLinks = {
  'fi-FI': COOKIE_POLICY_LINK_FI,
  'sv-SE': COOKIE_POLICY_LINK_SV,
  'sv-FI': COOKIE_POLICY_LINK_SV_FI,
  'en-FI': COOKIE_POLICY_LINK_EN_FI,
};

export const getPrivacyPolicyLink = (locale = 'fi-FI'): string =>
  privacyPolicyLinks[locale];

export const getServiceAgreementLink = (locale = 'fi-FI'): string =>
  serviceAgreementLinks[locale];

export const getCookiePolicyLink = (locale = 'fi-FI'): string =>
  cookiePolicyLinks[locale];

export const getLegalPageLink = (locale: string): string => {
  switch (locale) {
    case 'fi-FI':
      return LEGAL_PAGE_LINK_FI_FI;
    case 'en-FI':
      return LEGAL_PAGE_LINK_EN_FI;
    case 'sv-FI':
      return LEGAL_PAGE_LINK_SV_FI;
    case 'sv-SE':
      return LEGAL_PAGE_LINK_SV_SE;
    default:
      return LEGAL_PAGE_LINK_FI_FI;
  }
};

export type Language = {
  name: string;
  locale: string;
};

export type Country = {
  name: string;
  shortname: string;
  languages: Language[];
  hidden?: boolean;
};

export const countries: Country[] = [
  {
    name: 'Suomi',
    shortname: 'FI',
    languages: [
      {
        name: 'Suomi',
        locale: 'fi-FI',
      },
      {
        name: 'Svenska',
        locale: 'sv-FI',
      },
      {
        name: 'English',
        locale: 'en-FI',
      },
    ],
  },
  {
    name: 'Sverige',
    shortname: 'SE',
    languages: [
      {
        name: 'Svenska',
        locale: 'sv-SE',
      },
    ],
  },
];

export const translations = {
  'fi-FI': {
    onboarding1: {
      header: 'Tervetuloa Someturvaan!',
      body1: '',
      body2: 'Someturva',
      body3: ' auttaa sinua, jos olet kohdannut kiusaamista tai häirintää',
      continueButton: 'Aloita',
      speechBubbles: {
        nameCalling: 'Haukkuminen',
        spreadingLies: 'Valheiden levittäminen',
        threat: 'Uhkailu',
        fakeProfile: 'Feikkiprofiilit',
        harassment: 'Ahdistelu',
        whatAboutYou: 'Mitä sinulle on tapahtunut?',
      },
    },
    onboarding2: {
      header:
        'Pyydä apua ja saat ensiapuyksikkömme vastauksen nopeasti ja helposti.',
      list: {
        first: {
          header: 'Rikos vai ei?',
          body: 'Asiantuntijamme tietävät, missä kulkee sallitun raja. Kaikkea ei tarvitse sietää.',
        },
        second: {
          header: 'Henkinen ensiapu',
          body: 'Häirintä ja kiusaaminen voivat vaikuttaa negatiivisesti omaan jaksamiseen. Autamme sinua pitämään huolta hyvinvoinnistasi.',
        },
        third: {
          header: 'Toimintaohjeet',
          body: 'Annamme juuri sinulle räätälöidyt suositukset tilanteen ratkaisemiseksi. Osaamme antaa hyvät ohjeet myös someen.',
        },
      },
      continueButton: 'Jatka',
    },
    terms: {
      header:
        'Haluamme antaa aina parasta mahdollista apua sinun tilanteeseesi.',
      body: `Jotta tämä olisi mahdollista, sinun pitää hyväksyä palvelumme [käyttöehdot](${getServiceAgreementLink(
        'fi-FI',
      )}) ja antaa meille lupa käsitellä henkilötietojasi [tietosuojaselosteemme](${getPrivacyPolicyLink(
        'fi-FI',
      )}) mukaisesti. Yksityisyytesi on meille sydämen asia!`,
      continueButton: 'Jatka',
      termsAccepted: 'Hyväksyn palvelun käyttöehdot.',
      privacyPolicyAccepted:
        'Suostun tietosuojaselosteen mukaiseen henkilötietojen käsittelyyn.',
    },
    cookieConsent: {
      title:
        'Evästeet auttavat sovellustamme toimimaan sujuvasti ja turvallisesti.',
      description: `Valitse, mitä evästeitä käytät. Voit muuttaa evästeasetuksiasi milloin vain. [Lue lisää](${getCookiePolicyLink(
        'fi-FI',
      )})`,
      acceptAll: 'Hyväksyn kaikki',
      essentialOnly: 'Vain välttämättömät',
      goToSettings: 'Haluan muokata evästeitä',
    },
    functionalCookieConsent: {
      title:
        'Jotta voit aloittaa chatin, sinun on sallittava toiminnalliset evästeet. Sopiiko se sinulle? 🍪',
      explainer: 'Mitä ovat toiminnalliset evästeet?',
      accept: 'Kyllä',
      decline: 'Ei',
    },
    cookieSettings: {
      title: 'Hallitse evästeasetuksia',
      necessary: {
        title: '🍞 Välttämättömät',
        description:
          'Nämä evästeet ovat välttämättömiä sivuston toiminnan kannalta, eikä niitä voi poistaa käytöstä. Käytämme välttämättömiä evästeitä esimerkiksi valitsemiesi evästeasetusten muistamiseen ja turvaamaan pääsysi palveluun.',
        value: 'Aina päällä',
      },
      functional: {
        title: '🥞 Toiminnalliset',
        description:
          'Toiminnalliset evästeet mahdollistavat palvelumme lisätoiminnot, kuten asiakaspalvelu-chatin. Jos et hyväksy näitä evästeitä, kaikki palvelumme toiminnot eivät ehkä toimi oikein (et voi esimerkiksi lähettää meille viestiä chatissa).',
      },
      statistics: {
        title: '🎂 Mittaaminen ja analytiikka',
        description:
          'Nämä evästeet keräävät meille nimetöntä tilastotietoa. Tiedon avulla voimme seurata palvelumme käyttöä ja valvoa sen suorituskykyä. Mittaamisen evästeet mahdollistavat korkealaatuisen käyttäjäkokemuksen ja sen, että voimme tunnistaa sekä korjata mahdolliset palvelussamme olevat ongelmat nopeasti.',
      },
      acceptSettings: 'Tallenna ja jatka',
    },
    error: {
      back: 'Palaa alkuun',
      defaultError: 'Palvelussa tapahtui odottamaton virhe.',
      404: 'Pahus! Hakemaasi sivua ei löydy.',
      401: 'Sinulla ei ole lukuoikeutta.',
    },
    phone: {
      header: 'Kirjaudu sisään!',
      footer1:
        'Syötä alle puhelinnumerosi. Saat tekstiviestinä kertakäyttöisen salasanan, jolla pääset kirjautumaan palveluun.',
      emailLogin: 'Haluatko kirjautua sähköpostilla?',
      subheader: 'Miksi minun pitää antaa puhelinnumeroni',
      footer2:
        'Tarvitsemme puhelinnumerosi, koska sen avulla teemme sinulle  anonyymin käyttäjätilin. Puhelinnumero tallennetaan ulkoiseen turvalliseen holviin, mikä mahdollistaa sen, että voimme käsitellä tietojasi palvelussa anonyymisti.',
      error: {
        isRequired: 'Puhelinnumero on pakollinen tieto',
        cannotSend: 'Tekstiviestin lähetyksessä tapahtui virhe',
        invalidFormat: 'Puhelinnumero ei ole kelvollinen',
      },
    },
    email: {
      header: 'Kirjaudu sisään!',
      description1:
        'Syötä alle sähköpostiosoitteesi. Saat sähköpostiisi kertakäyttöisen salasanan, jolla pääset kirjautumaan palveluun.',
      description2:
        'Huom! Suosittelemme palveluun kirjautumista puhelinnumerolla. Näin ilmoittamaasi tapaukseen liittyvät viestit tavoittavat sinut parhaiten.',
      placeholder: 'Sähkoposti',
      phoneLogin: 'Kirjaudu puhelinnumerolla',
      subheader: 'Miksi minun pitää antaa sähköpostiosoitteeni',
      footer2:
        'Tarvitsemme sähköpostisi, koska sen avulla teemme sinulle anonyymin käyttäjätilin. Sähköpostisi tallennetaan ulkoiseen turvalliseen holviin, mikä mahdollistaa sen, että voimme käsitellä tietojasi palvelussa anonyymisti.',
      error: {
        isRequired: 'Sähköpostiosoite on pakollinen tieto',
        cannotSend: 'Viestin lähetyksessä tapahtui virhe',
        badDomain: 'Verkkotunnus ei ole sallittu',
        invalidFormat: 'Antamasi sähköpostiosoite ei ole kelvollinen',
      },
    },
    pin: {
      header: 'Syötä kertakäyttöinen salasana',
      descriptionPhone:
        'Saat muutaman minuutin kuluessa kuusinumeroisen salasanan tekstiviestinä numeroon {phoneNumber}.',
      descriptionEmail:
        'Saat muutaman minuutin kuluessa kuusinumeroisen salasanan sähköpostilla osoitteeseen {email}.',
      resendCodePhone:
        'Etkö saanut kertakäyttöistä salasanaa? Klikkaa [tästä](/) lähettääksesi uuden salasanan.',
      resendCodeEmail:
        'Etkö saanut viestiä? Klikkaa [tästä](/email), niin lähetämme sinulle uuden viestin.',
      error: {
        isRequired: 'Kertakäyttöinen salasana on pakollinen tieto.',
        invalidCode: 'Kertakäyttöinen salasana on virheellinen',
        loginAgain:
          'Olet syöttänyt virheellisen salasanan liian monta kertaa.\nYritä kirjautua uudelleen [tästä](/).',
      },
    },

    link: {
      header: 'Linkki on nyt lähetetty!',
      description:
        'Saat muutaman minuutin kuluessa kirjautumislinkin sähköpostiosoitteeseen {email}.',
      resendCode:
        'Etkö saanut viestiä? Klikkaa [tästä](/email), niin lähetämme sinulle uuden viestin.',
    },
    login: { header: 'Kirjaudutaan sisään' },
    logout: { header: 'Kirjaudutaan ulos' },
    voucher: {
      header: 'Aktivointikoodi',
      footer: 'Anna saamasi aktivointikoodi tähän',
      error: {
        isRequired: 'Aktivointikoodi on pakollinen tieto',
        invalidVoucher: 'Antamasi aktivointikoodi on virheellinen',
        validationFailure:
          'Aktivointikoodin varmistuksessa tapahtui virhe, yritä uudestaan.',
      },
      activate: {
        title: 'Olen',
        cancel: 'Ei, vaihda koodi',
        accept: 'Kyllä',
      },
      product: {
        PROFESSIONAL: 'koulun henkilökuntaa',
        STUDENT: 'koulun oppilas',
      },
    },
    nav_menu: {
      changeLanguage: 'Vaihda kieli',
      changeLanguageOther: 'Byt språk / Change language',
      help: 'Onko sinulla kysyttävää?',
      terms_of_service: 'Palvelun käyttöehdot',
      privacy_policy: 'Tietosuojaseloste',
      cookieSettings: 'Hallitse evästeasetuksia',
      termsAndProtection: 'Ehdot ja tietosuoja',
    },
    country: {
      header: 'Valitse maa, jossa asut',
      subheader: 'Välj landet du bor i / Choose the country you live in',
      continue: 'Jatka',
    },
    locale: {
      header: 'Valitse kieli',
      subheader: 'Välj språk / Choose language',
      continue: 'Jatka',
    },
  },
  'sv-SE': {
    onboarding1: {
      header: 'Välkommen till SomeBuddy!',
      body1: '',
      body2: 'SomeBuddy',
      body3:
        ' hjälper dig om du blivit utsatt för mobbning eller trakasserier.',
      continueButton: 'Börja',
      speechBubbles: {
        nameCalling: 'Förolämpning',
        spreadingLies: 'Spridning av lögner',
        threat: 'Hot',
        fakeProfile: 'Fejkprofiler',
        harassment: 'Trakassering',
        whatAboutYou: 'Vad har du fått utstå?',
      },
    },
    onboarding2: {
      header:
        'Be om hjälp så får du snabbt och enkelt svar av vår första hjälpen-enhet.',
      list: {
        first: {
          header: 'Brott eller inte?',
          body: 'Våra experter vet var gränsen mellan tillåtet och förbjudet går. Du behöver inte stå ut med allt.',
        },
        second: {
          header: 'Psykisk hjälp',
          body: 'Trakasserier och mobbning kan ha en negativ inverkan på det egna måendet. Vi hjälper dig att ta hand om ditt välmående.',
        },
        third: {
          header: 'Vägledning',
          body: 'Vi kommer att ge dig skräddarsydda rekommendationer för att lösa situationen. Vi kan även ge dig värdefulla instruktioner för sociala medier.',
        },
      },
      continueButton: 'Fortsätt',
    },
    terms: {
      header:
        'Vi strävar alltid efter att ge bästa möjliga hjälp i just din situation',
      body: `För att detta ska vara möjligt måste du godkänna våra [användarvillkor](${getServiceAgreementLink(
        'sv-SE',
      )}) och lämna ditt samtycke till behandling av av dina personuppgifter enligt vår [dataskyddspolicy](${getPrivacyPolicyLink(
        'sv-SE',
      )}). Din personliga integritet är viktig för oss.
      `,
      continueButton: 'Fortsätt',
      termsAccepted: 'Jag accepterar användarvillkoren.',
      privacyPolicyAccepted:
        'Jag lämnar mitt samtycke till behandling av personuppgifter enligt dataskyddspolicyn.',
    },
    cookieConsent: {
      title: 'Cookies hjälper vår applikation att fungera smidigt och säkert.',
      description: `Välj vilka cookies du använder. Du kan ändra dina cookieinställningar när som helst. [Läs mer](${getCookiePolicyLink(
        'sv-SE',
      )}).`,
      acceptAll: 'Godkänn alla',
      essentialOnly: 'Bara nödvändiga',
      goToSettings: 'Ändra på cookieinställningarna',
    },
    functionalCookieConsent: {
      title:
        'För att starta en chatt måste du aktivera funktionella cookies.\nÄr det ok för dig? 🍪',
      explainer: 'Vad är funktionella cookies?',
      accept: 'Ja',
      decline: 'Nej',
    },
    cookieSettings: {
      title: 'Hantera cookieinställningar',
      necessary: {
        title: '🍞 Nödvändiga',
        description:
          'Dessa kakor är nödvändiga för att webbplatsen ska fungera och kan inte inaktiveras. Vi använder nödvändiga kakor, till exempel för att komma ihåg dina cookieinställningar och för att säkerställa din åtkomst till tjänsten.',
        value: 'Alltid på',
      },
      functional: {
        title: '🥞 Funktionella',
        description:
          'Funktionella cookies möjliggör olika tilläggsfunktioner på vår webbplats, såsom kundtjänstchatten. Om du inte accepterar dessa cookies kan det hända att vissa funktioner på vår webbplats inte fungera ordentligt (du kan till exempel inte skicka oss chattmeddelanden).',
      },
      statistics: {
        title: '🎂 Analytiska',
        description:
          'Dessa cookies samlar in anonym statistik. Med hjälp av dessa kan vi spåra hur tjänsten används och kontrollera tjänstens effektivitet. Dessa kakor möjliggör en högkvalitativ användarupplevelse och att vi snabbt kan identifiera och korrigera eventuella problem i tjänsten. Dessa kan låta allvarliga - men oroa dig inte - för oss syns allt bara i siffror och statistik.',
      },
      acceptSettings: 'Spara och fortsätt',
    },
    error: {
      back: 'Till början',
      defaultError: 'Vi beklagar, ett fel uppstod.',
      404: 'Äsch! Sidan du begärde kunde inte hittas.',
      401: 'Du har inte läsåtkomst till detta.',
    },
    phone: {
      header: 'Logga in!',
      footer1:
        'Ange ditt telefonnummer nedan. Du får ett engångslösenord via SMS för att logga in på tjänsten.',
      emailLogin: 'Vill du logga in med e-post?',
      subheader: 'Varför måste jag ange mitt telefonnummer',
      footer2:
        'Vi använder ditt telefonnummer för att skapa ett anonymt användarkonto åt dig. Telefonnumret lagras i ett digitalt säkerhetsvalv, vilket gör att vi kan behandla din information helt anonymt i tjänsten.',
      error: {
        isRequired: 'Du måste fylla i ett giltigt telefonnummer',
        cannotSend: 'Det gick inte att skicka ett SMS',
        invalidFormat: 'Telefonnumret är inte giltigt',
      },
    },
    email: {
      header: 'Logga in!',
      description1:
        'Ange din e-post. Du får ett mejl med ett engångslösenord, för att logga in på tjänsten.',
      description2:
        'Obs! Vi rekommenderar att du loggar in på tjänsten med ett telefonnummer. Meddelandena relaterade till ditt ärende når dig bäst på så sätt.',
      placeholder: 'E-post',
      phoneLogin: 'Logga in med telefonnummer',
      subheader: 'Varför måste jag ange min e-post',
      footer2:
        'Vi använder din e-post för att skapa ett anonymt användarkonto åt dig. Din e-post lagras i ett digitalt säkerhetsvalv, vilket gör att vi kan behandla din information helt anonymt i tjänsten.',
      error: {
        isRequired: 'Du måste fylla i en giltig e-postadress',
        cannotSend: 'Det gick inte att skicka ett e-postmeddelande',
        badDomain: 'Domännamnet är inte tillåtet',
        invalidFormat: 'E-postadressen du angav är inte giltig',
      },
    },
    pin: {
      header: 'Verifieringskod',
      descriptionPhone:
        'Du får ett engångslösenord via SMS inom några minuter till numret {phoneNumber}.',
      descriptionEmail:
        'Du får ett sexsiffrigt engångslösenord via e-post inom några minuter till { email }.',
      resendCodePhone:
        'Fick du inget engångslösenord? Klicka [här](/) för att hämta en ny kod.',
      resendCodeEmail:
        'Fick du inget mejl? Klicka [här](/email), så skickar vi dig ett nytt mejl.',
      error: {
        isRequired: 'Du måste fylla i en verifieringskod',
        invalidCode: 'Engångslösenordet var ogiltigt',
        loginAgain:
          'Du har skrivit in fel lösenord för många gånger.\nFörsök att logga in igen [här](/).',
      },
    },
    link: {
      header: 'Länken har nu skickats!',
      description: 'Du får en inloggningslänk till {email} inom några minuter.',
      resendCode:
        'Fick du inget mejl? Klicka [här](/email), så skickar vi dig ett nytt mejl.',
    },
    login: { header: 'Loggas in' },
    logout: { header: 'Loggas ut' },
    voucher: {
      header: 'Aktiveringskod',
      footer: 'Ange aktiveringskoden här',
      error: {
        isRequired: 'Du måste fylla i en aktiveringskod',
        invalidVoucher: 'Aktiveringskod är ogiltig',
        validationFailure:
          'Någonting hände som avbröt aktiveringen. Var vänlig och försök igen.',
      },
      activate: {
        title: 'Jag är',
        cancel: 'Nej, byt kod',
        accept: 'Ja',
      },
      product: {
        PROFESSIONAL: 'skolpersonal',
        STUDENT: 'en skolelev',
      },
    },
    nav_menu: {
      changeLanguage: 'Byt språk',
      changeLanguageOther: 'Vaihda kieli / Change language',
      help: 'Behöver du hjälp?',
      terms_of_service: 'Tjänstens användarvillkor',
      privacy_policy: 'Dataskyddspolicy',
      cookieSettings: 'Hantera cookieinställningar',
      termsAndProtection: 'Villkor och dataskydd',
    },
    country: {
      header: 'Välj landet du bor i',
      subheader: 'Valitse maa, jossa asut / Choose the country you live in',
      continue: 'Fortsätt',
    },
    locale: {
      header: 'Välj språk',
      subheader: 'Valitse kieli / Choose language',
      continue: 'Fortsätt',
    },
  },
  'sv-FI': {
    onboarding1: {
      header: 'Välkommen till Someturva!',
      body1: '',
      body2: 'Someturva',
      body3:
        ' hjälper dig om du blivit utsatt för mobbning eller trakasserier.',
      continueButton: 'Börja',
      speechBubbles: {
        nameCalling: 'Förolämpning',
        spreadingLies: 'Spridning av lögner',
        threat: 'Hot',
        fakeProfile: 'Fejkprofiler',
        harassment: 'Trakassering',
        whatAboutYou: 'Vad har du fått utstå?',
      },
    },
    onboarding2: {
      header:
        'Be om hjälp så får du snabbt och enkelt svar av vår första hjälpen-enhet.',
      list: {
        first: {
          header: 'Brott eller inte?',
          body: 'Våra experter vet var gränsen mellan tillåtet och förbjudet går. Du behöver inte stå ut med allt.',
        },
        second: {
          header: 'Psykisk hjälp',
          body: 'Trakasserier och mobbning kan ha en negativ inverkan på det egna måendet. Vi hjälper dig att ta hand om ditt välmående.',
        },
        third: {
          header: 'Vägledning',
          body: 'Vi kommer att ge dig skräddarsydda rekommendationer för att lösa situationen. Vi kan även ge dig värdefulla instruktioner för sociala medier.',
        },
      },
      continueButton: 'Fortsätt',
    },
    terms: {
      header:
        'Vi strävar alltid efter att ge bästa möjliga hjälp i just din situation',
      body: `För att detta ska vara möjligt måste du godkänna våra [användarvillkor](${getServiceAgreementLink(
        'sv-FI',
      )}) och lämna ditt samtycke till behandling av av dina personuppgifter enligt vår [dataskyddspolicy](${getPrivacyPolicyLink(
        'sv-FI',
      )}). Din personliga integritet är viktig för oss.`,
      continueButton: 'Fortsätt',
      termsAccepted: 'Jag accepterar användarvillkoren.',
      privacyPolicyAccepted:
        'Jag lämnar mitt samtycke till behandling av personuppgifter enligt dataskyddspolicyn.',
    },
    cookieConsent: {
      title: 'Cookies hjälper vår applikation att fungera smidigt och säkert.',
      description: `Välj vilka cookies du använder. Du kan ändra dina cookieinställningar när som helst. [Läs mer](${getCookiePolicyLink(
        'sv-FI',
      )}).`,
      acceptAll: 'Godkänn alla',
      essentialOnly: 'Bara nödvändiga',
      goToSettings: 'Ändra på cookieinställningarna',
    },
    functionalCookieConsent: {
      title:
        'För att starta en chatt måste du aktivera funktionella cookies.\nÄr det ok för dig? 🍪',
      explainer: 'Vad är funktionella cookies?',
      accept: 'Ja',
      decline: 'Nej',
    },
    cookieSettings: {
      title: 'Hantera cookieinställningar',
      necessary: {
        title: '🍞 Nödvändiga',
        description:
          'Dessa kakor är nödvändiga för att webbplatsen ska fungera och kan inte inaktiveras. Vi använder nödvändiga kakor, till exempel för att komma ihåg dina cookieinställningar och för att säkerställa din åtkomst till tjänsten.',
        value: 'Alltid på',
      },
      functional: {
        title: '🥞 Funktionella',
        description:
          'Funktionella cookies möjliggör olika tilläggsfunktioner på vår webbplats, såsom kundtjänstchatten. Om du inte accepterar dessa cookies kan det hända att vissa funktioner på vår webbplats inte fungera ordentligt (du kan till exempel inte skicka oss chattmeddelanden).',
      },
      statistics: {
        title: '🎂 Analytiska',
        description:
          'Dessa cookies samlar in anonym statistik. Med hjälp av dessa kan vi spåra hur tjänsten används och kontrollera tjänstens effektivitet. Dessa kakor möjliggör en högkvalitativ användarupplevelse och att vi snabbt kan identifiera och korrigera eventuella problem i tjänsten. Dessa kan låta allvarliga - men oroa dig inte - för oss syns allt bara i siffror och statistik',
      },
      acceptSettings: 'Spara och fortsätt',
    },
    error: {
      back: 'Till början',
      defaultError: 'Vi beklagar, ett fel uppstod.',
      404: 'Äsch! Sidan du begärde kunde inte hittas.',
      401: 'Du har inte läsåtkomst till detta.',
    },
    phone: {
      header: 'Logga in!',
      footer1:
        'Ange ditt telefonnummer nedan. Du får ett engångslösenord via SMS för att logga in på tjänsten.',
      emailLogin: 'Vill du logga in med e-post?',
      subheader: 'Varför måste jag ange mitt telefonnummer',
      footer2:
        'Vi använder ditt telefonnummer för att skapa ett anonymt användarkonto åt dig. Telefonnumret lagras i ett digitalt säkerhetsvalv, vilket gör att vi kan behandla din information helt anonymt i tjänsten.',
      error: {
        isRequired: 'Du måste fylla i ett giltigt telefonnummer',
        cannotSend: 'Det gick inte att skicka ett SMS',
        invalidFormat: 'Telefonnumret är inte giltigt',
      },
    },
    email: {
      header: 'Logga in!',
      description1:
        'Ange din e-post. Du får ett mejl med ett engångslösenord, för att logga in på tjänsten.',
      description2:
        'Obs! Vi rekommenderar att du loggar in på tjänsten med ett telefonnummer. Meddelandena relaterade till ditt ärende når dig bäst på så sätt.',
      placeholder: 'E-post',
      phoneLogin: 'Logga in med telefonnummer',
      subheader: 'Varför måste jag ange min e-post',
      footer2:
        'Vi använder din e-post för att skapa ett anonymt användarkonto åt dig. Din e-post lagras i ett digitalt säkerhetsvalv, vilket gör att vi kan behandla din information helt anonymt i tjänsten.',
      error: {
        isRequired: 'Du måste fylla i en giltig e-postadress',
        cannotSend: 'Det gick inte att skicka ett e-postmeddelande',
        badDomain: 'Domännamnet är inte tillåtet',
        invalidFormat: 'The email address you entered is not valid',
      },
    },
    pin: {
      header: 'Engångslösenord',
      descriptionPhone:
        'Du får ett engångslösenord via SMS inom några minuter till numret {phoneNumber}.',
      descriptionEmail:
        'Du får ett sexsiffrigt engångslösenord via e-post inom några minuter till { email }.',
      resendCodePhone:
        'Fick du inget engångslösenord? Klicka [här](/) för att hämta ett nytt engångslösenord.',
      resendCodeEmail:
        'Fick du inget mejl? Klicka [här](/email), så skickar vi dig ett nytt mejl.',
      error: {
        isRequired: 'Du måste fylla i ett engångslösenord',
        invalidCode: 'Engångslösenordet var ogiltigt',
        loginAgain:
          'Du har skrivit in fel lösenord för många gånger.\nFörsök att logga in igen [här](/).',
      },
    },
    link: {
      header: 'Länken har nu skickats!',
      description: 'Du får en inloggningslänk till {email} inom några minuter.',
      resendCode:
        'Fick du inget mejl? Klicka [här](/email), så skickar vi dig ett nytt mejl.',
    },
    login: { header: 'Loggas in' },
    logout: { header: 'Loggas ut' },
    voucher: {
      header: 'Aktiveringskod',
      footer: 'Ange aktiveringskoden här',
      error: {
        isRequired: 'Du måste fylla i en aktiveringskod',
        invalidVoucher: 'Aktiveringskod är ogiltig',
        validationFailure:
          'Någonting hände som avbröt aktiveringen. Var vänlig och försök igen.',
      },
      activate: {
        title: 'Jag är',
        cancel: 'Nej, byt kod',
        accept: 'Ja',
      },
      product: {
        PROFESSIONAL: 'skolpersonal',
        STUDENT: 'en skolelev',
      },
    },
    nav_menu: {
      changeLanguage: 'Byt språk',
      changeLanguageOther: 'Vaihda kieli / Change language',
      help: 'Behöver du hjälp?',
      terms_of_service: 'Tjänstens användarvillkor',
      privacy_policy: 'Dataskyddspolicy',
      cookieSettings: 'Hantera cookieinställningar',
      termsAndProtection: 'Villkor och dataskydd',
    },
    country: {
      header: 'Välj landet du bor i',
      subheader: 'Valitse maa, jossa asut / Choose the country you live in',
      continue: 'Fortsätt',
    },
    locale: {
      header: 'Välj språk',
      subheader: 'Valitse kieli / Choose language',
      continue: 'Fortsätt',
    },
  },
  'en-FI': {
    onboarding1: {
      header: 'Welcome to Someturva!',
      body1: '',
      body2: 'Someturva',
      body3: ' helps you if you have been bullied or harassed.',
      continueButton: 'Start',
      speechBubbles: {
        nameCalling: 'Name-calling',
        spreadingLies: 'Blackmailing',
        threat: 'Threats',
        fakeProfile: 'Fake profiles',
        harassment: 'Harassment',
        whatAboutYou: 'Mitä sinulle on tapahtunut?',
      },
    },
    onboarding2: {
      header:
        'Ask for help and get a response from our emergency unit quickly and easily.',
      list: {
        first: {
          header: 'Crime or not',
          body: 'Our experts know where to draw the line. Not everything has to be tolerated.',
        },
        second: {
          header: 'Psychological first aid',
          body: 'Being bullied or harassed can have a negative impact on your well-being. We help you to take care of yourself.',
        },
        third: {
          header: 'Action plan',
          body: 'We will give you tailored recommendations to solve the situation. We can also give you good advice on social media.',
        },
      },
      continueButton: 'Continue',
    },
    terms: {
      header:
        'We always want to provide the best possible help for your situation.',
      body: `
      To do this, you must accept our [Terms of Use](${getServiceAgreementLink(
        'en-FI',
      )}) and allow us process your personal data in line with our [Privacy Policy](${getPrivacyPolicyLink(
        'en-FI',
      )}). Your privacy is important to us!`,
      continueButton: 'Continue',
      termsAccepted: 'I accept the Terms of Use.',
      privacyPolicyAccepted:
        'I consent to the processing of my personal data in accordance with the Privacy Policy.',
    },
    cookieConsent: {
      title: 'Cookies help our app to run smoothly and securely.',
      description: `Choose which cookies you use. You can change your cookie settings at any time. [Read more](${getCookiePolicyLink(
        'en-FI',
      )}).`,
      acceptAll: 'Accept all',
      essentialOnly: 'Only necessary',
      goToSettings: 'Edit cookie settings',
    },
    functionalCookieConsent: {
      title:
        'To start a chat you need to enable functional cookies.\nIs that OK with you? 🍪',
      explainer: 'What are functional cookies?',
      accept: 'Yes',
      decline: 'No',
    },
    cookieSettings: {
      title: 'Set your cookie preferencies',
      necessary: {
        title: '🍞 Essentials',
        description:
          'These cookies are necessary for the functioning of the site and cannot be disabled. We use essential cookies, for example, to remember the cookie settings you have chosen and to secure your access to the service.',
        value: 'Always on',
      },
      functional: {
        title: '🥞 Functional',
        description:
          'Functional cookies enable additional features, such as the customer service chat. If you do not accept these cookies, some features of our service may not work properly (for example, you may not be able to send us a message in the chat).',
      },
      statistics: {
        title: '🎂 Measurements and analytics',
        description:
          'These cookies collect anonymous statistical information for us. This information allows us to monitor the use and performance of our service. Measurement cookies allow us to provide high quality user experience and to quickly identify and fix any problems with our service.',
      },
      acceptSettings: 'Save and continue',
    },
    error: {
      back: 'Continue',
      defaultError: 'Ups! Something unexpected happened.',
      404: 'Dang! This page does not exists.',
      401: 'Sorry! You do not have access to this information.',
    },
    phone: {
      header: 'Log in!',
      footer1:
        'You will receive a one-time password by text message to log into the service.',
      subheader: 'Why do you need my phone number?',
      emailLogin: 'Do you want to log in by email?',
      footer2:
        'We need your phone number because we will use it to create an anonymous user account for you. Your phone number is stored in an external secure vault, which allows us to process your data anonymously in the service.',
      error: {
        isRequired: 'You must enter a valid phone number',
        cannotSend: 'The text message could not be sent',
        invalidFormat: 'The phone number you entered is not valid',
      },
    },
    email: {
      header: 'Log in!',
      description1:
        'You will receive a one-time password by email to log into the service.',
      description2:
        'Note! We recommend that you log in with your phone number. This way, messages related to your case will reach you better.',
      placeholder: 'Email',
      phoneLogin: 'Sign up by phone number',
      subheader: 'Why do you need my email?',
      footer2:
        'We need your email because we will use it to create an anonymous user account for you. Your email is stored in an external secure vault, which allows us to process your data anonymously in the service.',
      error: {
        isRequired: 'You must enter a valid email address',
        cannotSend: 'The email could not be sent',
        badDomain: 'Domain name is not allowed',
        invalidFormat: 'E-postadressen du angav är inte giltig',
      },
    },
    pin: {
      header: 'Enter the one-time password',
      descriptionPhone:
        'You will receive a text message within a couple of minutes containing a six-digit password to {phoneNumber}.',
      descriptionEmail:
        'You will receive an email within a couple of minutes containing a six-digit password to {email}.',
      resendCodePhone:
        "Didn't get a one-time password? Click [here](/) to get a new code.",
      resendCodeEmail:
        "Didn't get a one-time password? Click [here](/email) to get a new code.",
      error: {
        isRequired: 'You must enter the one-time password',
        invalidCode: 'The one-time password was invalid',
        loginAgain:
          'You have entered an incorrect password too many times.\nTry to log in again [here](/).',
      },
    },

    link: {
      header: 'Linkki on nyt lähetetty!',
      description:
        'Saat muutaman minuutin kuluessa kirjautumislinkin sähköpostiosoitteeseen {email}.',
      resendCode:
        'Etkö saanut viestiä? Klikkaa [tästä](/email), niin lähetämme sinulle uuden viestin.',
    },
    login: { header: 'Logging in' },
    logout: { header: 'Logging out' },
    voucher: {
      header: 'Activation code',
      footer: 'Please enter the activation code you were given.',
      error: {
        isRequired: 'You must enter an activation code',
        invalidVoucher: 'The activation code was invalid',
        validationFailure:
          'Something happened that interrupted the activation. Please try again.',
      },
      activate: {
        title: 'I am',
        cancel: 'No, change the code',
        accept: 'Yes',
      },
      product: {
        PROFESSIONAL: 'a school professional',
        STUDENT: 'a student',
      },
    },
    nav_menu: {
      changeLanguage: 'Change language',
      changeLanguageOther: 'Vaihda kieli / Byt språk',
      help: 'Do you have any questions?',
      terms_of_service: 'Terms of service',
      privacy_policy: 'Privacy policy',
      cookieSettings: 'Cookie settings',
      termsAndProtection: 'Terms & Privacy',
    },
    locale: {
      header: 'Choose language',
      subheader: 'Vaihda kieli / Välj språk',
      continue: 'Continue',
    },
    country: {
      header: 'Choose the country you live in',
      subheader: 'Valitse maa, jossa asut / Välj landet du bor i',
      continue: 'Continue',
    },
  },
};

frenchkiss.set('en-FI', translations['en-FI']);
frenchkiss.set('fi-FI', translations['fi-FI']);
frenchkiss.set('sv-SE', translations['sv-SE']);
frenchkiss.set('sv-FI', translations['sv-FI']);

const TranslationLink = ({ linkUrl, onClick, linkText, target }) => {
  const isExternalSite =
    linkUrl.startsWith('https://') || linkUrl.startsWith('http://');
  return isExternalSite ? (
    <a
      href={linkUrl}
      onClick={(event): void => onClick(event, linkUrl)}
      target={target}
    >
      {linkText}
    </a>
  ) : (
    <Link href={linkUrl}>
      <a onClick={(event): void => onClick(event, linkUrl)} target={target}>
        {linkText}
      </a>
    </Link>
  );
};

TranslationLink.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
};

interface Opts {
  target?: string;
  onClick?: (x: any, y: any) => any;
}

export const handleLinks = (
  str: string,
  elements = [],
  opts: Opts = {},
): string | JSX.Element[] => {
  const linkRegExpMatch = /\[([^\]]*)\]\(([^)]*)\)/g.exec(str);

  if (!linkRegExpMatch) {
    return [...elements, str];
  }
  const { target = '_self', onClick = () => {} } = opts;
  const {
    index: matchIndex,
    0: match,
    1: linkText,
    2: linkUrl,
  } = linkRegExpMatch;
  const textBeforeLink = str.substring(0, matchIndex);

  const textAfterLink = str.substring(matchIndex + match.length, str.length);
  const element = (
    <span key={matchIndex + linkUrl + linkText}>
      {textBeforeLink}
      <TranslationLink
        linkUrl={linkUrl}
        linkText={linkText}
        target={target}
        onClick={(event, url) => onClick(event, url)}
      />
    </span>
  );
  return handleLinks(textAfterLink, [...elements, element], opts);
};

export default frenchkiss;
