import * as amplitude from '@amplitude/analytics-browser';
import { getStatisticsCookie } from './cookie';
import env from './env';

import type { TransportType } from '@amplitude/analytics-types';

const { AMPLITUDE_API_KEY = '' } = env;
export default class Analytics {
  static setTrackingOptions(user = {}) {
    if (getStatisticsCookie()) {
      amplitude.init(AMPLITUDE_API_KEY, null, {
        optOut: false,
        defaultTracking: false,
      });
      this.setUserProperties(user);
      console.info(
        `User has opted-in into analytics tracking. (Thank you! This helps us make our product better, and we don't track any personal/identifiable data.`,
      );
    } else {
      amplitude.init(AMPLITUDE_API_KEY, null, {
        optOut: true,
        defaultTracking: true,
      });
      console.info(`User has opted-out from analytics tracking.`);
    }
  }

  static logEvent(name, properties?) {
    amplitude.track(`${name}`, properties);
  }

  static logClick(buttonName, properties?) {
    amplitude.track(`${buttonName}:click`, properties);
  }

  static logSubmit(name, properties?) {
    amplitude.track(`${name}:submit`, properties);
  }

  static logComplete(name, properties?) {
    amplitude.track(`${name}:complete`, properties);
  }

  static logInitiate(name, properties?) {
    amplitude.track(`${name}:initiate`, properties);
    amplitude.setTransport('beacon' as TransportType);
    amplitude.flush();
  }

  static setLocale(locale) {
    const identify = new amplitude.Identify().set('locale', locale);
    amplitude.identify(identify);
  }

  static setUserProperties(user) {
    const identify = new amplitude.Identify();
    Object.keys(user).map((key) => identify.set(key, user[key]));
    amplitude.identify(identify);
  }
}
