import { useContext } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { parseCookies } from 'nookies';
import SomisLogo from '../Header/SomisLogo';
import { theme, width } from '../../styles/theme';
import { PeikkoContext } from '../../contexts/PeikkoContext';
import { SmallNavMenuButton, SmallestNavMenuButton } from './NavMenuButton';
import { getLegalPageLink, countries } from '../i18n';
import { DisableBodyScroll } from '../utils';
import {
  resetCookiesConsent,
  isCookiesConsentGiven,
  getCookieConsent,
} from '../../utils/cookie';
import { getCountryFromLocale } from '../../utils/helpers';

const showLocaleSelector = (user) => {
  const locale = user?.locale || parseCookies()?.locale || 'NO_LOCALE';
  const countryNameCode = getCountryFromLocale(locale);

  if (!countryNameCode) return false;
  if (countryNameCode === 'PRO') return false;
  const country = countries.find((c) => c.shortname === countryNameCode);
  return country && country.languages && country.languages.length > 1;
};

const styledNavMenutProps = {
  isOpen: false,
};

type StyledNavMenutProps = Readonly<typeof styledNavMenutProps>;

type StyledNavMenuProps = Partial<StyledNavMenutProps>;

const StyledNavMenu = styled.div.attrs<{ $isOpen: boolean }>((props) => ({
  $isOpen: props.$isOpen,
}))<StyledNavMenuProps>`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  height: 100%;
  background-color: ${theme.palette.secondary};
  transition: 100ms linear;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
  overflow-y: scroll;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const NavMenuWrapper = styled.div`
  max-width: ${width.container}px;
  width: 100%;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  max-width: 80px;
`;

const DividerLine = styled.div`
  background-color: rgba(255, 255, 255, 0.14);
  height: 1px;
  width: 100%;
`;

const DividerContainer = styled.div`
  height: 1px;
  width: 100%;
  padding: 0 20px;
  margin: 25px 0;
`;

const Divider = () => (
  <DividerContainer>
    <DividerLine />
  </DividerContainer>
);

// Imported dynamically so that Crisp only gets rendered client-side
const HelpButton = dynamic(() => import('./HelpButton'), { ssr: false });

type CloseButtonLineProps = {
  rotate: number;
};

const CloseButtonLine = styled.span<CloseButtonLineProps>`
  top: 0;
  width: 2px;
  height: 20px;
  background-color: ${theme.palette.background};
  transform: rotate(${({ rotate }) => rotate}deg);
  position: absolute;
`;

const CloseButtonContainer = styled.button`
  background-color: transparent;
  border: none;
  position: relative;
  width: 20px;
  height: 20px;
`;

const CloseButton = (props) => (
  <CloseButtonContainer {...props}>
    <CloseButtonLine rotate={45} />
    <CloseButtonLine rotate={315} />
  </CloseButtonContainer>
);

const ButtonContainer = styled.div`
  width: 100%;
`;

const TopRowContainer = styled.div`
  padding: 25px 25px 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

interface NavMenuProps {
  isOpen: boolean;
  closeMenu: () => void;
  user: User;
}

const NavMenu = ({
  isOpen,
  closeMenu,
  user,
}: NavMenuProps): React.ReactElement => {
  const { i18n } = useContext(PeikkoContext);
  const router = useRouter();

  const { locale } = i18n;

  const goToCookieSettings = () => {
    resetCookiesConsent();
    router.push('/cookies/settings');
    closeMenu();
  };

  const goToLocaleSettings = () => {
    router.push('/locale');
    closeMenu();
  };

  return (
    <>
      {isOpen ? <DisableBodyScroll /> : null}
      <StyledNavMenu $isOpen={isOpen}>
        <NavMenuWrapper>
          <ButtonContainer>
            <TopRowContainer>
              <LogoContainer>
                <SomisLogo fill={theme.palette.background} />
              </LogoContainer>
              <CloseButton onClick={closeMenu} />
            </TopRowContainer>
            <HelpButton i18n={i18n} onClick={closeMenu} />
          </ButtonContainer>
          <Divider />
          <ButtonContainer>
            {showLocaleSelector(user) && (
              <SmallNavMenuButton onClick={goToLocaleSettings}>
                {i18n.t('nav_menu.changeLanguage')}
                <br />
                <SmallestNavMenuButton>
                  {i18n.t('nav_menu.changeLanguageOther')}
                </SmallestNavMenuButton>
              </SmallNavMenuButton>
            )}
            <a
              href={getLegalPageLink(locale)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SmallNavMenuButton>
                {i18n.t('nav_menu.termsAndProtection')}
              </SmallNavMenuButton>
            </a>
          </ButtonContainer>
          {isCookiesConsentGiven(getCookieConsent()) ? (
            <>
              <Divider />
              <ButtonContainer>
                <SmallNavMenuButton onClick={goToCookieSettings}>
                  {i18n.t('nav_menu.cookieSettings')}
                </SmallNavMenuButton>
              </ButtonContainer>
            </>
          ) : null}
        </NavMenuWrapper>
      </StyledNavMenu>
    </>
  );
};

export default NavMenu;
