import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import NextErrorComponent from 'next/error';
import styled from 'styled-components';
import { NextPageContext, NextPage } from 'next';
import * as Sentry from '@sentry/nextjs';
import Button from '../components/Button';
import { PeikkoContext } from '../contexts/PeikkoContext';
import { CenteredP, SadAvatar } from '../components/PageElements';
import { setToken } from '../utils/cookie';

export const VisualContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20vh;
  flex-grow: 1;
  justify-content: center;
`;

type ErrorPageProps = {
  statusCode?: number;
  err?: any;
  hasGetInitialPropsRun?: boolean;
};

const PeikkoError: NextPage<ErrorPageProps> = (props: ErrorPageProps) => {
  const { err, hasGetInitialPropsRun, statusCode } = props;
  const { i18n, setShowHeaderLogo } = useContext(PeikkoContext);
  const router = useRouter();

  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
    // Flushing is not required in this case as it only happens on the client
  }
  useEffect(() => {
    setShowHeaderLogo(false);
    return () => setShowHeaderLogo(true);
  }, []);

  const getErrorMessage = () => {
    const key = `error.${statusCode}`;
    const errorByStatusCode = i18n.t(key);
    if (
      statusCode &&
      errorByStatusCode !== key &&
      errorByStatusCode !== `[missing:${key}]`
    ) {
      return errorByStatusCode;
    }
    return i18n.t('error.defaultError');
  };

  const onClick = () => {
    // Force user to re-authenticate because cookies might be messed up
    // causing a redirect loop with Toosa
    setToken('', 0);
    router.push('/');
  };

  return (
    <VisualContainer>
      <SadAvatar />
      <CenteredP>{getErrorMessage()}</CenteredP>
      <Button onClick={onClick}>{i18n.t('error.back')}</Button>
    </VisualContainer>
  );
};

PeikkoError.getInitialProps = async (context: NextPageContext) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps(context);
  const { res, err, asPath } = context;

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run

  // errorInitialProps.hasGetInitialPropsRun = true;

  // Returning early because we don't want to log 404 errors to Sentry.
  if (res?.statusCode === 404) {
    return errorInitialProps;
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  const errorTitle = `_error.js getInitialProps missing data at path: ${asPath}`;
  Sentry.captureException(new Error(errorTitle));
  await Sentry.flush(2000);

  return errorInitialProps;
};

PeikkoError.defaultProps = {
  statusCode: null,
  err: null,
  hasGetInitialPropsRun: true,
};

export default PeikkoError;
