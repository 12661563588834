import React, { createContext, useState } from 'react';
import { DefaultAnimations } from '../components/Avatar/AvatarAnimations';

interface AvatarContextType {
  singleAnimation: {
    animation: any;
    timestamp: number;
  };
  animationQueue: any[];
  playSingleAnimation: (animation: any) => void;
  setAnimationQueue: (animationQueue: any) => void;
}

interface AvatarContextProps {
  children: React.ReactElement;
}

export const AvatarContext = createContext<AvatarContextType | null>(null);

export const AvatarProvider = ({ children }: AvatarContextProps) => {
  const [singleAnimation, setSingleAnimation] = useState({
    animation: null,
    timestamp: null,
  });

  const [animationQueue, setAnimationQueue] = useState(DefaultAnimations);

  const playSingleAnimation = (animation) =>
    setSingleAnimation({ animation, timestamp: new Date().getTime() });
  return (
    <AvatarContext.Provider
      value={{
        singleAnimation,
        animationQueue,
        playSingleAnimation,
        setAnimationQueue,
      }}
    >
      {children}
    </AvatarContext.Provider>
  );
};
