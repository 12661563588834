import styled from 'styled-components';
import React, { useEffect, useContext } from 'react';
import cc from 'classcat';
import { width, height, theme } from '../styles/theme';
import { AvatarContext } from '../contexts/AvatarContext';
import { Animations, AnimationsAdult } from './Avatar/AvatarAnimations';
import Avatar from './Avatar/Avatar';
import { ADULT_PRODUCT_TYPES } from '../utils/constants';

export const PageElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 70vh;
  text-align: left;
  margin-left: 25px;
  margin-right: 25px;
  position: relative;

  > *:first-child {
    margin-top: 20px;

    @media (max-width: ${width.phone}px) {
      margin-top: 0;
    }

    @media (max-height: ${height.medium + 26}px) {
      margin-top: 15px;
    }
  }

  > :last-child {
    margin-bottom: 0px;
  }
`;

export const P = styled.p`
  font-family: 'Gramatika', sans-serif;
  line-height: 1.2em;
  max-width: 60ch;
  color: ${theme.palette.secondary};
  text-align: left;
  margin-bottom: 18px;
  font-size: 1.4em;

  @media (min-width: ${width.phone}px) {
    font-size: 2em;
  }
`;

export const LargeP = styled(P)`
  font-size: 1.5em;
  margin-bottom: 20px;

  @media (min-width: ${width.phone}px) {
    font-size: 2.4em;
    margin-bottom: 56px;
  }
`;

export const MediumP = styled(P)`
  margin: 0;
  margin-bottom: 0.1rem;
  font-weight: 600;
  font-family: 'Helvetica', sans-serif;

  && {
    font-size: 0.9rem;
  }

  @media (min-width: ${width.tablet}px) {
    font-size: 1rem;
  }
`;
export const SmallP = styled(P)`
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 300;
  font-family: 'Helvetica', sans-serif;
  line-height: 1.1rem;
  & {
    font-size: 0.85rem;
  }
  & + & {
    margin-top: -1em;
  }
`;

export const CheckboxP = styled(SmallP)`
  & {
    margin: 0;
    padding-bottom: 5px;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
`;

export const CenteredP = styled(P)`
  width: 100%;
  text-align: center;
`;

export const PageInputContainer = styled.div`
  margin-bottom: 1rem;
  @media (max-width: ${width.phone}px) {
    width: 100%;
  }
`;

export const NoHeaderPageContainer = styled(PageElements)`
  margin-top: 2rem;
`;

type WelcomingAvatarProps = {
  product: Product;
};

export const WelcomingAvatar = ({
  product,
}: WelcomingAvatarProps): JSX.Element => {
  const { playSingleAnimation } = useContext(AvatarContext);
  useEffect(
    () =>
      product
        ? playSingleAnimation(
            ADULT_PRODUCT_TYPES.includes(product)
              ? AnimationsAdult.still
              : Animations.waving0,
          )
        : playSingleAnimation(Animations.buddle),
    [product],
  );
  return <Avatar width={140} height={140} />;
};

export const SadAvatar = (): JSX.Element => {
  const { playSingleAnimation } = useContext(AvatarContext);
  useEffect(() => playSingleAnimation(Animations.sad0), []);
  return <Avatar width={140} height={140} />;
};

export const Label = styled.label``;

export const LabelText = styled.div`
  display: none;
`;

type ErrorContainerProps = {
  children: React.ReactNode;
  className: string;
};

export const ErrorContainer = ({
  children,
  className,
}: ErrorContainerProps): React.ReactElement => (
  <div className={cc(['h-6 mb-12', className])}>{children}</div>
);
