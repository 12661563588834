import React, { useEffect, useState } from 'react';
import i18n, { handleLinks } from '../components/i18n';

import { getCountryPhoneCodeByLocale } from '../utils/locale';

export const PeikkoContext =
  React.createContext<Partial<PeikkoProviderState>>(null);

interface PeikkoProviderState {
  pinNumber: any;
  phoneNumber: any;
  sendingMessage: boolean;
  validatingPin: boolean;
  token: string;
  i18n: I18n;
  showHeaderLogo: boolean;
  countryPhoneCode: string;
  email: string;
  setCountryPhoneCode: (code: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setEmail: (email: string) => void;
  setToken: (token: string) => void;
  setSendingMessage: (sendingMessage: boolean) => void;
  setShowHeaderLogo: (showHeaderLogo: boolean) => void;
}

type PeikkoProviderProps = {
  children: React.ReactElement<any>[];
  locale: Locale;
} & Partial<DefaultProps>;

type DefaultProps = { locale: Locale };

export function PeikkoProvider({
  children,
  locale: initialLocale = 'fi-FI',
}: PeikkoProviderProps) {
  const [locale, updateLocale] = useState(initialLocale);
  const [countryPhoneCode, setCountryPhoneCode] = useState(
    getCountryPhoneCodeByLocale(locale),
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [token, setToken] = useState(null);
  const [showHeaderLogo, setShowHeaderLogo] = useState(true);

  useEffect(() => {
    setCountryPhoneCode(getCountryPhoneCodeByLocale(locale));
  }, [locale]);

  return (
    <PeikkoContext.Provider
      value={{
        countryPhoneCode,
        setCountryPhoneCode,
        i18n: {
          locale,
          updateLocale,
          t: (key, args, opts) =>
            handleLinks(i18n.t(key, args, locale), [], opts),
        },
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        sendingMessage,
        setSendingMessage,
        token,
        setToken,
        showHeaderLogo,
        setShowHeaderLogo,
      }}
    >
      {children}
    </PeikkoContext.Provider>
  );
}
