module.exports = {
  theme: {
    palette: {
      'background-grey': '#eff0f4',
      'primary-blue': '#2f5ad3',
      'primary-grey-body': '#77889c',
      'dark-blue-grey': '#1d2d42',
      'light-blue-grey': '#c6d3e0',
      'light-grey': '#dee0e7',
      white: '#ffffff',
      red: '#ee0004',
      background: '#fff',
      primary: '#3debcc',
      secondary: '#393832',
      highlight: '#007aff',
      typography: '#2a3e39',
      grey: '#f4f4f4',
      error: '#ee0004',
      disabled: '#d0d0d0',
    },
  },
  width: {
    megawide: 1600,
    widescreen: 1200,
    desktop: 992,
    tablet: 768,
    phone: 576,
    phoneM: 425,
    phoneS: 320,
    container: 768,
  },
  height: {
    small: 500,
    medium: 640,
    large: 812,
  },
};
