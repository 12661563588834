export const createUrl = (host, path = '') => {
  const protocol = host === 'localhost:3000' ? 'http' : 'https';
  return `${protocol}://${host}${path}`;
};

export const getHost = (ctx): string =>
  ctx && ctx.req ? ctx.req.headers.host : window.location.host;

type CtxType = {
  req?: any;
  res?: any;
};

export const isServerRender = (ctx: CtxType = {}): boolean =>
  !!ctx.req && !!ctx.res;
