export const GDPR_CONSENT_GIVEN = 'gdprConsentGiven';
export const GDPR_STATISTICS_ENABLED = 'gdprStatisticsEnabled';
export const GDPR_MARKETING_ENABLED = 'gdprMarketingEnabled';
export const GDPR_FUNCTIONAL_ENABLED = 'gdprFunctionalEnabled';

export const PRODUCT_TYPES = {
  STUDENT: 'STUDENT',
  PERSONAL: 'PERSONAL',
  PROFESSIONAL: 'PROFESSIONAL',
  MANAGER: 'MANAGER',
};

export const ADULT_PRODUCT_TYPES = [
  PRODUCT_TYPES.PERSONAL,
  PRODUCT_TYPES.MANAGER,
];
